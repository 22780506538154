<template>
    <Button label="Adicionar" icon="pi pi-plus" class="mr-2" @click="showDialog()" v-if="operacao == 1" />
    <font-awesome-icon icon="pen" @click="showDialog()" size="lg" class="icon-button p-text-info" title="Editar" v-if="operacao == 2" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '70vw' }" :maximizable="false" :closable="false">
        <template #header>
            <h3>Cadastro - Instituições</h3>
        </template>
        <erros-box :erros="erros"></erros-box>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label class="required">CNPJ</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cpfCnpj" mask="99.999.999/9999-99" :unmask="true" placeholder="CNPJ" @input="v$.cpfCnpj.$touch()" />
                    <Button title="Buscar CNPJ na Receita Federal" icon="pi pi-search-plus" @click="consultaCnpjReceita()" />
                </div>
                <small class="p-error" v-if="v$.cpfCnpj.$error">O CPF/CNPJ é obrigatório</small>
            </div>
            <div class="field col-8">
                <label class="required">Razão Social</label>
                <InputText type="text" v-model="razaoSocial" @input="v$.razaoSocial.$touch()" />
                <small class="p-error" v-if="v$.razaoSocial.$error">A Razão Social é obrigatória</small>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-4">
                <label>Natureza Jurídica</label>
                <Dropdown v-model="idNaturezaJuridica" :options="comboNaturezaJuridica" optionLabel="descricao" :filter="true" optionValue="id" />
                <small class="p-error" v-if="v$.idNaturezaJuridica.$error">A Natureza Jurídica é obrigatória</small>
            </div>
            <div class="field col-4">
                <label class="required">Área de Atuação Principal</label>
                <Dropdown
                    v-model="idAreaAtuacaoPrincipal"
                    :options="comboAreaAtuacao"
                    optionLabel="descricao"
                    :filter="true"
                    optionValue="id"
                    @input="v$.idAreaAtuacaoPrincipal.$touch()"
                />
                <small class="p-error" v-if="v$.idAreaAtuacaoPrincipal.$error">A Área de atuação é obrigatória</small>
            </div>
            <div class="field col-4">
                <label class="required">Setor</label>
                <Dropdown v-model="idSetor" :options="comboSetor" optionLabel="descricao" :filter="true" optionValue="id" @input="v$.idSetor.$touch()" />
                <small class="p-error" v-if="v$.idSetor.$error">O Setor é obrigatória</small>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-2">
                <label>Cep</label>
                <div class="p-inputgroup">
                    <InputMask v-model="pessoaDto.Cep" mask="99999-999" :unmask="true" placeholder="CEP" />
                    <Button title="Buscar Cep nos Correios" icon="pi pi-search-plus" @click="ConsultaCep()" />
                </div>
            </div>
            <div class="field col-8">
                <label>Endereço</label>
                <InputText type="text" v-model="pessoaDto.Endereco" />
            </div>
            <div class="field col-2">
                <label>Número</label>
                <InputText type="text" v-model="pessoaDto.Numero" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-6">
                <label>Bairro</label>
                <InputText type="text" v-model="pessoaDto.Bairro" />
            </div>
            <div class="field col-6">
                <label>Complemento</label>
                <InputText type="text" v-model="pessoaDto.Complemento" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-10">
                <label>Cidade</label>
                <InputText type="text" v-model="pessoaDto.Cidade" />
            </div>
            <div class="field col-2">
                <label>UF</label>
                <InputText type="text" v-model="pessoaDto.Uf" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-3">
                <label class="required">Telefone</label>
                <InputMask v-model="telefone" mask="(99)99999-9999" :unmask="true" :autoClear="false" />
            </div>

            <div class="field col-7">
                <label>Departamento</label>
                <InputText type="text" v-model="pessoaDto.Departamento" />
            </div>

            <div class="field col-2 field-checkbox">
                <Checkbox :binary="true" v-model="redeAtendimentoChecked" />
                <label for="accept">Rede de atendimento</label>
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label>Descrição Instituição</label>
                <Textarea type="text" v-model="pessoaDto.DescricaoInstituicao" rows="3" />
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-12">
                <label>Tags</label>
                <InputText type="text" v-model="pessoaDto.Tags" />
            </div>
        </div>

        <div class="card">
            <div class="flex">
                <h5>Personas</h5>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Secretarias e Agências Governamentais</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckSecretariasAgenciasGovernamentais" />
                </div>
                <div class="field col-3">
                    <label for="accept">Prefeituras, Secretarias, Autarquias e Agências Municipais</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckPrefeiturasSecretariasAutarquiasAgenciasMunicipais" />
                </div>
                <div class="field col-3">
                    <label for="accept">Associações Comerciais e CDLs</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckAssociacoesComerciaisCDL" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Instituições de referência em temáticas e notório saber</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckInstituicoesReferenciaTematicasSaber" />
                </div>
                <div class="field col-3">
                    <label for="accept">Instituições de Ensino</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckInstituicoesEnsino" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Entidades voltadas para inovação</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckEntidadesVoltadasInovacao" />
                </div>
                <div class="field col-3">
                    <label for="accept">Patrocinadoras de eventos, projetos ou programas</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckPatrocinadorasEventosProjetosProgramas" />
                </div>
                <div class="field col-3">
                    <label for="accept">Prestadoras de serviços voluntários especializados</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckPrestadorasServicosVoluntarios" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Realizadora de grandes eventos de mercado</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckRealizadorasGrandesEventosMercado" />
                </div>
                <div class="field col-3">
                    <label for="accept">Representações setoriais ou de classe</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckRepresentacoesSetoriaisClasses" />
                </div>
                <div class="field col-3">
                    <label for="accept">Grandes federações, Sistema "S" e Associadas</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckGrandesFederacoesSistemasAssociadas" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Lideranças de grande porte</label>
                </div>
                <div class="field col-1">
                    <Checkbox :binary="true" v-model="ckLiderancasGrandePorte" />
                </div>
            </div>
        </div>

        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        tipo: {
            type: Number,
        },
        operacao: {
            type: Number,
        },
        idPessoa: {
            type: Number,
        },
    },

    emits: ['atualizarLista'],

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            cpfCnpj: { required },
            razaoSocial: { required },
            idNaturezaJuridica: { required },
            idAreaAtuacaoPrincipal: { required },
            idSetor: { required },
            telefone: { required },
        };
    },

    data() {
        return {
            display: false,
            tipoPessoa: 'Não Definido',
            erros: [],
            comboSetor: [],
            comboAreaAtuacao: [],
            cpfCnpj: null,
            razaoSocial: null,
            redeAtendimentoChecked: false,
            telefone: null,
            ckSecretariasAgenciasGovernamentais: false,
            ckPrefeiturasSecretariasAutarquiasAgenciasMunicipais: false,
            ckAssociacoesComerciaisCDL: false,
            ckInstituicoesReferenciaTematicasSaber: false,
            ckInstituicoesEnsino: false,
            ckEntidadesVoltadasInovacao: false,
            ckPatrocinadorasEventosProjetosProgramas: false,
            ckPrestadorasServicosVoluntarios: false,
            ckRealizadorasGrandesEventosMercado: false,
            ckRepresentacoesSetoriaisClasses: false,
            ckGrandesFederacoesSistemasAssociadas: false,
            ckLiderancasGrandePorte: false,
            idNaturezaJuridica: null,
            idSetor: null,
            idAreaAtuacaoPrincipal: null,

            pessoaDto: {
                Id: 0,
                CpfCnpj: '',
                IdNaturezaJuridica: 0,
                RazaoSocial: '',
                Cep: '',
                Endereco: '',
                Numero: '',
                Complemento: '',
                Bairro: '',
                Cidade: '',
                Uf: '',
                Departamento: '',
                DescricaoInstituicao: '',
                RedeAtendimento: 0,
                TipoOrganizacao: 0,
                IdSetor: 0,
                IdAreaAtuacaoPrincipal: 0,
                Tags: '',
                UsuarioAtualizacao: '',
                DataCadastro: '',
                DataAtualizacao: '',

                SecretariasAgenciasGovernamentais: 0,
                PrefeiturasSecretariasAutarquiasAgenciasMunicipais: 0,
                AssociacoesComerciaisCDL: 0,
                InstituicoesReferenciaTematicasSaber: 0,
                InstituicoesEnsino: 0,
                EntidadesVoltadasInovacao: 0,
                PatrocinadorasEventosProjetosProgramas: 0,
                PrestadorasServicosVoluntarios: 0,
                RealizadorasGrandesEventosMercado: 0,
                RepresentacoesSetoriaisClasses: 0,
                GrandesFederacoesSistemasAssociadas: 0,
                LiderancasGrandePorte: 0,
            },
        };
    },

    methods: {
        showDialog() {
            this.display = true;

            this.cpfCnpj = null;
            this.razaoSocial = null;
            this.limparComponentes();

            this.carregaComboAreaAtuacao();
            this.carregaComboSetor();

            this.carregaComboNaturezaJuridica();

            if (this.tipo == 1) {
                this.tipoPessoa = 'Parceiro';
            } else if (this.tipo == 2) {
                this.tipoPessoa = 'Interlocutor';
            }

            if (this.operacao == 2) {
                this.obterPorId();
            }
        },

        limparComponentes() {
            this.pessoaDto.Id = 0;
            this.cpfCnpj = '';
            this.telefone = '';
            this.idNaturezaJuridica = 0;
            this.razaoSocial = '';
            this.pessoaDto.Cep = '';
            this.pessoaDto.Endereco = '';
            this.pessoaDto.Numero = '';
            this.pessoaDto.Complemento = '';
            this.pessoaDto.Bairro = '';
            this.pessoaDto.Cidade = '';
            this.pessoaDto.Uf = '';
            this.pessoaDto.Departamento = '';
            this.pessoaDto.DescricaoInstituicao = '';
            this.pessoaDto.RedeAtendimento = 0;
            this.pessoaDto.TipoOrganizacao = 0;
            this.pessoaDto.IdSetor = 0;
            this.idAreaAtuacaoPrincipal = 0;
            this.pessoaDto.Tags = '';
            this.pessoaDto.UsuarioAtualizacao = '';
            this.pessoaDto.DataCadastro = new Date();
            this.pessoaDto.DataAtualizacao = new Date();
            this.pessoaDto.SecretariasAgenciasGovernamentais = 0;
            this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais = 0;
            this.pessoaDto.AssociacoesComerciaisCDL = 0;
            this.pessoaDto.SecretariasAgenciasGovernamentais = 0;
            this.pessoaDto.InstituicoesReferenciaTematicasSaber = 0;
            this.pessoaDto.InstituicoesEnsino = 0;
            this.pessoaDto.EntidadesVoltadasInovacao = 0;
            this.pessoaDto.PatrocinadorasEventosProjetosProgramas = 0;
            this.pessoaDto.PrestadorasServicosVoluntarios = 0;
            this.pessoaDto.RealizadorasGrandesEventosMercado = 0;
            this.pessoaDto.RepresentacoesSetoriaisClasses = 0;
            this.pessoaDto.GrandesFederacoesSistemasAssociadas = 0;
            this.pessoaDto.LiderancasGrandePorte = 0;

            this.ckSecretariasAgenciasGovernamentais = false;
            this.ckPrefeiturasSecretariasAutarquiasAgenciasMunicipais = false;
            this.ckAssociacoesComerciaisCDL = false;
            this.ckInstituicoesReferenciaTematicasSaber = false;
            this.ckInstituicoesEnsino = false;
            this.ckEntidadesVoltadasInovacao = false;
            this.ckPatrocinadorasEventosProjetosProgramas = false;
            this.ckPrestadorasServicosVoluntarios = false;
            this.ckRealizadorasGrandesEventosMercado = false;
            this.ckRepresentacoesSetoriaisClasses = false;
            this.ckGrandesFederacoesSistemasAssociadas = false;
            this.ckLiderancasGrandePorte = false;
            this.erros = [];
            this.v$.$reset();
        },

        CarregarComponentes(data) {
            this.pessoaDto.Id = data.id;
            this.cpfCnpj = data.cpfCnpj;
            this.pessoaDto.cpfCnpj = data.cpfCnpj;
            this.telefone = data.telefone;

            this.idNaturezaJuridica = data.idNaturezaJuridica;
            this.pessoaDto.idNaturezaJuridica = data.idNaturezaJuridica;
            this.razaoSocial = data.razaoSocial;
            this.pessoaDto.razaoSocial = data.razaoSocial;
            this.pessoaDto.Cep = data.cep;
            this.pessoaDto.Endereco = data.endereco;
            this.pessoaDto.Numero = data.numero;
            this.pessoaDto.Complemento = data.complemento;
            this.pessoaDto.Bairro = data.bairro;
            this.pessoaDto.Cidade = data.cidade;
            this.pessoaDto.Uf = data.uf;
            this.pessoaDto.Departamento = data.departamento;
            this.pessoaDto.DescricaoInstituicao = data.descricaoInstituicao;
            this.pessoaDto.RedeAtendimento = data.redeAtendimento;
            this.pessoaDto.TipoOrganizacao = data.tipoOrganizacao;
            this.idSetor = data.idSetor;
            this.pessoaDto.idSetor = data.idSetor;
            this.idAreaAtuacaoPrincipal = data.idAreaAtuacaoPrincipal;
            this.pessoaDto.Tags = data.tags;
            this.pessoaDto.UsuarioAtualizacao = data.usuarioAtualizacao;
            this.pessoaDto.DataCadastro = data.dataCadastro;
            this.pessoaDto.DataAtualizacao = new Date();

            this.pessoaDto.SecretariasAgenciasGovernamentais = data.secretariasAgenciasGovernamentais;
            this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais = data.prefeiturasSecretariasAutarquiasAgenciasMunicipais;
            this.pessoaDto.AssociacoesComerciaisCDL = data.associacoesComerciaisCDL;
            this.pessoaDto.InstituicoesReferenciaTematicasSaber = data.instituicoesReferenciaTematicasSaber;
            this.pessoaDto.InstituicoesEnsino = data.instituicoesEnsino;
            this.pessoaDto.EntidadesVoltadasInovacao = data.entidadesVoltadasInovacao;
            this.pessoaDto.PatrocinadorasEventosProjetosProgramas = data.patrocinadorasEventosProjetosProgramas;
            this.pessoaDto.PrestadorasServicosVoluntarios = data.prestadorasServicosVoluntarios;
            this.pessoaDto.RealizadorasGrandesEventosMercado = data.realizadorasGrandesEventosMercado;
            this.pessoaDto.RepresentacoesSetoriaisClasses = data.representacoesSetoriaisClasses;
            this.pessoaDto.GrandesFederacoesSistemasAssociadas = data.grandesFederacoesSistemasAssociadas;
            this.pessoaDto.LiderancasGrandePorte = data.liderancasGrandePorte;

            this.redeAtendimentoChecked = false;
            if (this.pessoaDto.RedeAtendimento == 1) {
                this.redeAtendimentoChecked = true;
            }

            this.ckSecretariasAgenciasGovernamentais = this.pessoaDto.SecretariasAgenciasGovernamentais == 1;
            this.ckPrefeiturasSecretariasAutarquiasAgenciasMunicipais = this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais == 1;
            this.ckAssociacoesComerciaisCDL = this.pessoaDto.AssociacoesComerciaisCDL == 1;
            this.ckInstituicoesReferenciaTematicasSaber = this.pessoaDto.InstituicoesReferenciaTematicasSaber == 1;
            this.ckInstituicoesEnsino = this.pessoaDto.InstituicoesEnsino == 1;
            this.ckEntidadesVoltadasInovacao = this.pessoaDto.EntidadesVoltadasInovacao == 1;
            this.ckPatrocinadorasEventosProjetosProgramas = this.pessoaDto.PatrocinadorasEventosProjetosProgramas == 1;
            this.ckPrestadorasServicosVoluntarios = this.pessoaDto.PrestadorasServicosVoluntarios == 1;
            this.ckRealizadorasGrandesEventosMercado = this.pessoaDto.RealizadorasGrandesEventosMercado == 1;
            this.ckRepresentacoesSetoriaisClasses = this.pessoaDto.RepresentacoesSetoriaisClasses == 1;
            this.ckGrandesFederacoesSistemasAssociadas = this.pessoaDto.GrandesFederacoesSistemasAssociadas == 1;
            this.ckLiderancasGrandePorte = this.pessoaDto.LiderancasGrandePorte == 1;
        },

        closeDialog() {
            this.v$.$reset();
            this.display = false;
        },

        obterPorId() {
            this.$store.dispatch('addRequest');
            Services.SelectByIdPessoa(this.idPessoa).then((response) => {
                if (response && response.success) {
                    this.CarregarComponentes(response.data);
                } else {
                    this.pessoaDto = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboAreaAtuacao() {
            this.$store.dispatch('addRequest');
            Services.SelectAllAreaAtuacao().then((response) => {
                if (response && response.success) {
                    this.comboAreaAtuacao = response.data;
                } else {
                    this.comboAreaAtuacao = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboSetor() {
            this.$store.dispatch('addRequest');
            Services.SelectAllSetor().then((response) => {
                if (response && response.success) {
                    this.comboSetor = response.data;
                } else {
                    this.comboSetor = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        carregaComboNaturezaJuridica() {
            this.$store.dispatch('addRequest');
            Services.SelectAllNaturezaJuridica().then((response) => {
                if (response && response.success) {
                    this.comboNaturezaJuridica = response.data;
                } else {
                    this.comboNaturezaJuridica = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        consultaCnpjReceita() {
            var cpfcnpj = this.cpfCnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');

            if (cpfcnpj.length == 14) {
                this.$store.dispatch('addRequest');
                Services.ConsultaCnpjReceitaFederal(cpfcnpj).then((response) => {
                    if (response && response.success) {
                        this.cpfCnpj = cpfcnpj;
                        this.razaoSocial = response.data.nome;
                        this.pessoaDto.Cep = response.data.cep.replace('.', '').replace('-', '');
                        this.pessoaDto.Endereco = response.data.logradouro;
                        this.pessoaDto.Numero = response.data.numero;
                        this.pessoaDto.Complemento = response.data.complemento;
                        this.pessoaDto.Bairro = response.data.bairro;
                        this.pessoaDto.Cidade = response.data.municipio;
                        this.pessoaDto.Uf = response.data.uf;
                        this.idNaturezaJuridica = response.data.idNaturezaJuridica;
                    } else {
                        alert('Cnpj Informado não foi Localizado');
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                alert('Cnpj Informado inválido');
            }
        },

        ConsultaCep() {
            var cep = this.pessoaDto.Cep.replace('-', '');
            if (cep.length == 8) {
                this.$store.dispatch('addRequest');
                Services.ConsultaCepInterlocutor(cep).then((response) => {
                    if (response && response.success) {
                        this.pessoaDto.Cep = cep;
                        this.pessoaDto.Endereco = response.data.logradouro;
                        this.pessoaDto.Bairro = response.data.bairro;
                        this.pessoaDto.Cidade = response.data.localidade;
                        this.pessoaDto.Uf = response.data.uf;
                    } else {
                        alert('Cep Informado não foi Localizado');
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                alert('Cep Informado inválido');
            }
        },

        salvar() {
            this.pessoaDto.SecretariasAgenciasGovernamentais = 0;
            if (this.ckSecretariasAgenciasGovernamentais) {
                this.pessoaDto.SecretariasAgenciasGovernamentais = 1;
            }
            this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais = 0;
            if (this.ckPrefeiturasSecretariasAutarquiasAgenciasMunicipais) {
                this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais = 1;
            }
            this.pessoaDto.AssociacoesComerciaisCDL = 0;
            if (this.ckAssociacoesComerciaisCDL) {
                this.pessoaDto.AssociacoesComerciaisCDL = 1;
            }
            this.pessoaDto.InstituicoesReferenciaTematicasSaber = 0;
            if (this.ckInstituicoesReferenciaTematicasSaber) {
                this.pessoaDto.InstituicoesReferenciaTematicasSaber = 1;
            }
            this.pessoaDto.InstituicoesEnsino = 0;
            if (this.ckInstituicoesEnsino) {
                this.pessoaDto.InstituicoesEnsino = 1;
            }
            this.pessoaDto.EntidadesVoltadasInovacao = 0;
            if (this.ckEntidadesVoltadasInovacao) {
                this.pessoaDto.EntidadesVoltadasInovacao = 1;
            }
            this.pessoaDto.PatrocinadorasEventosProjetosProgramas = 0;
            if (this.ckPatrocinadorasEventosProjetosProgramas) {
                this.pessoaDto.PatrocinadorasEventosProjetosProgramas = 1;
            }
            this.pessoaDto.PrestadorasServicosVoluntarios = 0;
            if (this.ckPrestadorasServicosVoluntarios) {
                this.pessoaDto.PrestadorasServicosVoluntarios = 1;
            }
            this.pessoaDto.RealizadorasGrandesEventosMercado = 0;
            if (this.ckRealizadorasGrandesEventosMercado) {
                this.pessoaDto.RealizadorasGrandesEventosMercado = 1;
            }
            this.pessoaDto.RepresentacoesSetoriaisClasses = 0;
            if (this.ckRepresentacoesSetoriaisClasses) {
                this.pessoaDto.RepresentacoesSetoriaisClasses = 1;
            }
            this.pessoaDto.GrandesFederacoesSistemasAssociadas = 0;
            if (this.ckGrandesFederacoesSistemasAssociadas) {
                this.pessoaDto.GrandesFederacoesSistemasAssociadas = 1;
            }
            this.pessoaDto.LiderancasGrandePorte = 0;
            if (this.ckLiderancasGrandePorte) {
                this.pessoaDto.LiderancasGrandePorte = 1;
            }

            let pessoaDto = {
                Id: this.pessoaDto.Id,
                CpfCnpj: this.cpfCnpj,
                IdNaturezaJuridica: this.idNaturezaJuridica,
                RazaoSocial: this.razaoSocial,
                Cep: this.pessoaDto.Cep,
                Endereco: this.pessoaDto.Endereco,
                Numero: this.pessoaDto.Numero,
                Complemento: this.pessoaDto.Complemento,
                Bairro: this.pessoaDto.Bairro,
                Cidade: this.pessoaDto.Cidade,
                Uf: this.pessoaDto.Uf,
                Telefone: this.telefone,
                Departamento: this.pessoaDto.Departamento,
                DescricaoInstituicao: this.pessoaDto.DescricaoInstituicao,
                RedeAtendimento: this.pessoaDto.RedeAtendimento,
                TipoOrganizacao: this.pessoaDto.TipoOrganizacao,
                IdSetor: this.idSetor,
                IdAreaAtuacaoPrincipal: this.idAreaAtuacaoPrincipal,
                Tags: this.pessoaDto.Tags,
                UsuarioAtualizacao: this.pessoaDto.UsuarioAtualizacao,
                DataCadastro: this.pessoaDto.DataCadastro,
                DataAtualizacao: this.pessoaDto.DataAtualizacao,

                SecretariasAgenciasGovernamentais: this.pessoaDto.SecretariasAgenciasGovernamentais,
                PrefeiturasSecretariasAutarquiasAgenciasMunicipais: this.pessoaDto.PrefeiturasSecretariasAutarquiasAgenciasMunicipais,
                AssociacoesComerciaisCDL: this.pessoaDto.AssociacoesComerciaisCDL,
                InstituicoesReferenciaTematicasSaber: this.pessoaDto.InstituicoesReferenciaTematicasSaber,
                InstituicoesEnsino: this.pessoaDto.InstituicoesEnsino,
                EntidadesVoltadasInovacao: this.pessoaDto.EntidadesVoltadasInovacao,
                PatrocinadorasEventosProjetosProgramas: this.pessoaDto.PatrocinadorasEventosProjetosProgramas,
                PrestadorasServicosVoluntarios: this.pessoaDto.PrestadorasServicosVoluntarios,
                RealizadorasGrandesEventosMercado: this.pessoaDto.RealizadorasGrandesEventosMercado,
                RepresentacoesSetoriaisClasses: this.pessoaDto.RepresentacoesSetoriaisClasses,
                GrandesFederacoesSistemasAssociadas: this.pessoaDto.GrandesFederacoesSistemasAssociadas,
                LiderancasGrandePorte: this.pessoaDto.LiderancasGrandePorte,

                Setor: null,
                AreaAtuacaoPrincipal: null,
                PessoaTipo: null,
                PessoaAreaAtuacao: null,
                PessoaMidias: null,
                InterlocutoresParceiros: null,
                NaturezaJuridica: null,
            };

            if (this.operacao == 1) {
                if (this.tipo == 1) {
                    this.$store.dispatch('addRequest');
                    Services.InserirParceiro(pessoaDto).then((response) => {
                        if (response && response.success) {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Usuário',
                                detail: 'Usuário inserido com sucesso',
                                life: 3000,
                            });

                            this.$emit('atualizarLista');
                            this.closeDialog();
                        } else {
                            this.erros = response.errors;
                        }
                        this.$store.dispatch('removeRequest');
                    });
                } else if (this.tipo == 2) {
                    this.$store.dispatch('addRequest');
                    Services.InserirInterlocutor(pessoaDto).then((response) => {
                        if (response && response.success) {
                            this.$emit('atualizarLista');
                        }
                        this.$store.dispatch('removeRequest');
                        this.closeDialog();
                    });
                }
            } else if (this.operacao == 2) {
                this.$store.dispatch('addRequest');
                Services.AlterarPessoa(pessoaDto).then((response) => {
                    if (response && response.success) {
                        this.$emit('atualizarLista');
                    }
                    this.$store.dispatch('removeRequest');
                    this.closeDialog();
                });
            }
        },
    },

    mounted() {
        //this.tipoPessoa = this.$route.params.tipo;
    },
};
</script>
