<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <div class="flex">
                    <h5>Parça - Listagem das Instituições</h5>
                </div>

                <!--msg-box :itens="mensagens"></msg-box-->

                <tabela :data="lista">
                    <template #botoes>
                        <CadPessoa :tipo="1" :operacao="1" @atualizarLista="listar()" v-if="$temAcessoView('MAPAPARC-PES-01') && !externo"></CadPessoa>
                    </template>

                    <template #conteudo>
                        <Column headerStyle="width: 7em">
                            <template #body="slotProps">
                                <Detalhes :idPessoa="slotProps.data.id" :tipo="1"></Detalhes>
                                <CadPessoa :tipo="1" :operacao="2" :idPessoa="slotProps.data.id" @atualizarLista="listar()" v-if="$temAcessoView('MAPAPARC-PES-02') && !externo"></CadPessoa>
                                <font-awesome-icon icon="trash-alt" @click="confirmaDelete(slotProps.data.id)" size="lg" class="icon-button ml-2" title="Deletar" style="color: red" v-if="$temAcessoView('MAPAPARC-PES-03') && !externo" />
                            </template>
                        </Column>

                        <Column field="cpfCnpj" header="CNPJ" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.cpfCnpj }}
                            </template>
                        </Column>
                        <Column field="razaoSocial" header="Razão Social" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.razaoSocial }}
                            </template>
                        </Column>
                        <Column field="cidade" header="Cidade" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.cidade }}
                            </template>
                        </Column>
                        <Column field="uf" header="UF" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.uf }}
                            </template>
                        </Column>
                        <Column field="tags" header="Tags" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.tags }}
                            </template>
                        </Column>
                        <Column field="statusFinanceiroDescricao" header="Status Financeiro" :sortable="true">
                            <template #body="slotProps">
                                <status :status="slotProps.data.statusFinanceiroDescricao"></status>
                            </template>
                        </Column>
                        <Column field="qtdDiasInadimplente" header="Dias Situação" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.qtdDiasInadimplente }}
                            </template>
                        </Column>
                    </template>
                </tabela>
            </div>
        </div>
    </div>
    <Dialog header="Confirmação" v-model:visible="showDialogExcluir" :style="{ width: '350px' }" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" @click="closeConfirmation(false)" class="p-button-text" />
            <Button label="Sim" icon="pi pi-check" @click="closeConfirmation(true)" class="p-button-text" autofocus />
        </template>
    </Dialog>
    <router-view></router-view>
</template>

<script>
import Services from '../../service.js';
import Detalhes from '../detalhes/DetalhesPessoa.vue';
import CadPessoa from '../cadastro/CadPessoa.vue';

export default {
    components: {
        Detalhes,
        CadPessoa,
    },

    props: {
        filtroParceiro: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            showDialogExcluir: false,
            idExclusao: 0,
            tipoPessoa: 'Não Definido',
            lista: [],
            externo: false,
        };
    },

    methods: {
        listar() {
            this.listarParceiros();
        },

        listarParceiros() {
            var filtro;
            if (this.filtroParceiro == null) {
                filtro = {
                    cnpj: '',
                    razaoSocial: '',
                    situacao: '',
                };
            } else {
                filtro = {
                    cnpj: this.filtroParceiro.cnpj,
                    razaoSocial: this.filtroParceiro.razaoSocial,
                    situacao: this.filtroParceiro.situacao,
                };
            }
            this.$store.dispatch('addRequest');
            Services.SelectAllParceiros(filtro).then((response) => {
                if (response && response.success) {
                    this.lista = response.data;
                } else {
                    this.lista = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmaDelete(id) {
            this.showDialogExcluir = true;
            this.idExclusao = id;
        },

        closeConfirmation(confirmacao) {
            if (confirmacao) {
                this.deletar();
            }
            this.showDialogExcluir = false;
        },

        deletar() {
            this.$store.dispatch('addRequest');
            Services.DeletarPessoa(this.idExclusao).then((response) => {
                if (response && response.success) {
                    
                    this.listar();
                }else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Certificados',
                        detail: 'A instituição possui informações associadas.',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.tipoPessoa = this.$route.params.tipo;
        if (this.filtroParceiro != null) {
            this.externo = true;
        }
        this.listar();
    },
};
</script>