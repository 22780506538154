<template>
    <font-awesome-icon icon="info-circle" @click="showDialog()" size="lg" class="icon-button p-text-primary mr-2" title="Detalhar" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '70vw' }" :maximizable="true">
        <template #header>
            <h3>Detalhes - Instituições: {{ pessoa.razaoSocial }}</h3>
        </template>

        <div class="grid">
            <div class="col-6">
                <div class="mb-4">
                    <detalhe titulo="Id: " :size="sizeForm">{{ pessoa.id }}</detalhe>
                    <detalhe titulo="Cnpj: " :size="sizeForm">{{ pessoa.cpfCnpj }}</detalhe>
                    <detalhe titulo="Razão Social: " :size="sizeForm">{{ pessoa.razaoSocial }}</detalhe>
                    <detalhe titulo="Cep: " :size="sizeForm">{{ pessoa.cep }}</detalhe>
                    <detalhe titulo="Endereço: " :size="sizeForm">{{ pessoa.endereco }}, {{ pessoa?.numero }}</detalhe>
                    <detalhe titulo="Complemento: " :size="sizeForm">{{ pessoa.complemento }}</detalhe>
                    <detalhe titulo="Bairro: " :size="sizeForm">{{ pessoa.bairro }}</detalhe>
                    <detalhe titulo="Cidade: " :size="sizeForm">{{ pessoa.cidade }} - {{ pessoa?.uf }}</detalhe>
                    <detalhe titulo="Telefone: " :size="sizeForm">{{ pessoa.telefone }}</detalhe>
                    <detalhe titulo="Tags: " :size="sizeForm">{{ pessoa.tags }}</detalhe>
                    <detalhe titulo="Dias Situação: " :size="sizeForm">{{ pessoa.qtdDiasInadimplente }}</detalhe>
                    <detalhe titulo="Status Financeiro" :size="sizeForm">
                        <status :status="pessoa.statusFinanceiroDescricao"></status>
                    </detalhe>
                </div>
            </div>
            <div class="col-6">
                <div class="mb-4">
                    <detalhe titulo="Setor: " :size="sizeForm">{{ pessoa?.setor?.descricao }}</detalhe>
                    <detalhe titulo="Departamento: " :size="sizeForm">{{ pessoa.Departamento }}</detalhe>
                    <detalhe titulo="Área Atuação Principal: " :size="sizeForm">{{ pessoa?.areaAtuacaoPrincipal?.descricao }}</detalhe>
                    <detalhe titulo="Natureza Jurídica: " :size="sizeForm">{{ pessoa?.naturezaJuridica?.descricao }}</detalhe>
                    <detalhe titulo="Faz parte da Rede de Atendimento: " :size="sizeForm">{{ pessoa.redeAtendimentoFormatada }}</detalhe>
                    <detalhe titulo="Descrição Instituicao: " :size="sizeForm">{{ pessoa?.descricaoInstituicao }}</detalhe>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="flex">
                <h5>Personas</h5>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Secretarias e Agências Governamentais</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.secretariasAgenciasGovernamentais == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.secretariasAgenciasGovernamentais == 0" />
                </div>
                <div class="field col-3">
                    <label for="accept">Prefeituras, Secretarias, Autarquias e Agências Municipais</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon
                        icon="check-circle"
                        size="lg"
                        class="icon-button p-text-success"
                        v-if="pessoa.prefeiturasSecretariasAutarquiasAgenciasMunicipais == 1"
                    />
                    <font-awesome-icon
                        icon="times-circle"
                        size="lg"
                        class="icon-button p-text-danger"
                        v-if="pessoa.prefeiturasSecretariasAutarquiasAgenciasMunicipais == 0"
                    />
                </div>

                <div class="field col-3">
                    <label for="accept">Associações Comerciais e CDLs</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.associacoesComerciaisCDL == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.associacoesComerciaisCDL == 0" />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Serviços Financeiro / Crédito</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.servicosFinanceiroCredito == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.servicosFinanceiroCredito == 0" />
                </div>
                <div class="field col-3">
                    <label for="accept">Instituições de referência em temáticas e notório saber</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon
                        icon="check-circle"
                        size="lg"
                        class="icon-button p-text-success"
                        v-if="pessoa.instituicoesReferenciaTematicasSaber == 1"
                    />
                    <font-awesome-icon
                        icon="times-circle"
                        size="lg"
                        class="icon-button p-text-danger"
                        v-if="pessoa.instituicoesReferenciaTematicasSaber == 0"
                    />
                </div>
                <div class="field col-3">
                    <label for="accept">Instituições de Ensino</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.instituicoesEnsino == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.instituicoesEnsino == 0" />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Entidades voltadas para inovação</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.entidadesVoltadasInovacao == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.entidadesVoltadasInovacao == 0" />
                </div>
                <div class="field col-3">
                    <label for="accept">Patrocinadoras de eventos, projetos ou programas</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon
                        icon="check-circle"
                        size="lg"
                        class="icon-button p-text-success"
                        v-if="pessoa.patrocinadorasEventosProjetosProgramas == 1"
                    />
                    <font-awesome-icon
                        icon="times-circle"
                        size="lg"
                        class="icon-button p-text-danger"
                        v-if="pessoa.patrocinadorasEventosProjetosProgramas == 0"
                    />
                </div>
                <div class="field col-3">
                    <label for="accept">Prestadoras de serviços voluntários especializados</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.prestadorasServicosVoluntarios == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.prestadorasServicosVoluntarios == 0" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Realizadora de grandes eventos de mercado</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.realizadorasGrandesEventosMercado == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.realizadorasGrandesEventosMercado == 0" />
                </div>
                <div class="field col-3">
                    <label for="accept">Representações setoriais ou de classe</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.representacoesSetoriaisClasses == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.representacoesSetoriaisClasses == 0" />
                </div>
                <div class="field col-3">
                    <label for="accept">Grandes federações, Sistema "S" e Associadas</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon
                        icon="check-circle"
                        size="lg"
                        class="icon-button p-text-success"
                        v-if="pessoa.grandesFederacoesSistemasAssociadas == 1"
                    />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.grandesFederacoesSistemasAssociadas == 0" />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="field col-3">
                    <label for="accept">Lideranças de grande porte</label>
                </div>
                <div class="field col-1">
                    <font-awesome-icon icon="check-circle" size="lg" class="icon-button p-text-success" v-if="pessoa.liderancasGrandePorte == 1" />
                    <font-awesome-icon icon="times-circle" size="lg" class="icon-button p-text-danger" v-if="pessoa.liderancasGrandePorte == 0" />
                </div>
            </div>
        </div>

        <TabView>
            <TabPanel header="Outras Áreas de Atuação" v-if="tipo == 1 && $temAcessoView('MAPAPARC-AATU-00')">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div class="flex">
                                <h5>Áreas de Atuação</h5>
                            </div>
                            <tabela :data="pessoa.pessoaAreaAtuacao">
                                <template #botoes>
                                    <CadAreaAtuacao
                                        :pessoa="pessoa"
                                        @atualizarLista="obterPessoa()"
                                        v-if="pessoa && $temAcessoView('MAPAPARC-AATU-01')"
                                    ></CadAreaAtuacao>
                                </template>
                                <template #conteudo>
                                    <Column headerStyle="width: 3em">
                                        <template #body="slotProps">
                                            <font-awesome-icon
                                                icon="trash-alt"
                                                @click="confirmaDeleteArea(slotProps.data)"
                                                size="lg"
                                                class="icon-button"
                                                title="Deletar"
                                                style="color: red"
                                                v-if="$temAcessoView('MAPAPARC-AATU-03')"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="areaAtuacao.descricao" header="Descrição" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.areaAtuacao?.descricao }}
                                        </template>
                                    </Column>
                                </template>
                            </tabela>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Endereços web e mídias digitais" v-if="tipo == 1 && $temAcessoView('MAPAPARC-PAR-MID-00')">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div class="flex">
                                <h5>Mídias Digitais</h5>
                            </div>
                            <tabela :data="pessoa.pessoaMidias">
                                <template #botoes>
                                    <CadMidia
                                        :pessoa="pessoa"
                                        @atualizarLista="obterPessoa()"
                                        v-if="pessoa && $temAcessoView('MAPAPARC-PAR-MID-01')"
                                    ></CadMidia>
                                </template>
                                <template #conteudo>
                                    <Column headerStyle="width: 3em">
                                        <template #body="slotProps">
                                            <font-awesome-icon
                                                icon="trash-alt"
                                                @click="confirmaDeleteMidia(slotProps.data)"
                                                size="lg"
                                                class="icon-button"
                                                title="Deletar"
                                                style="color: red"
                                                v-if="$temAcessoView('MAPAPARC-PAR-MID-03')"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="tipo" header="Tipo" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.tipo }}
                                        </template>
                                    </Column>
                                    <Column field="endereco" header="Endereço" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.endereco }}
                                        </template>
                                    </Column>
                                </template>
                            </tabela>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Interlocutores" v-if="tipo == 1 && $temAcessoView('MAPAPARC-PAR-INT-00')">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div class="flex">
                                <h5>Interlocutores</h5>
                            </div>
                            <tabela :data="pessoa.interlocutoresParceiros">
                                <template #botoes>
                                    <CadInterlocutores
                                        :pessoa="pessoa"
                                        @atualizarLista="obterPessoa()"
                                        v-if="pessoa && $temAcessoView('MAPAPARC-PAR-INT-01')"
                                    ></CadInterlocutores>
                                </template>
                                <template #conteudo>
                                    <Column headerStyle="width: 3em">
                                        <template #body="slotProps">
                                            <font-awesome-icon
                                                icon="trash-alt"
                                                @click="confirmaDeleteInterlocutores(slotProps.data)"
                                                size="lg"
                                                class="icon-button"
                                                title="Deletar"
                                                style="color: red"
                                                v-if="$temAcessoView('MAPAPARC-PAR-INT-03')"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="interlocutor.nome" header="Nome" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.interlocutor.nome }}
                                        </template>
                                    </Column>
                                    <Column field="interlocutor.telefoneInstitucional" header="Telefone Institucional" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.interlocutor.telefoneInstitucional }}
                                        </template>
                                    </Column>
                                    <Column field="interlocutor.emailInstitucional" header="E-mail Institucional" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.interlocutor.emailInstitucional }}
                                        </template>
                                    </Column>
                                </template>
                            </tabela>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel header="Acordos de Parcerias" v-if="tipo == 1 && $temAcessoView('MAPAPARC-PAR-00')">
                <div class="grid">
                    <div class="col-12">
                        <div class="card">
                            <div class="flex">
                                <h5>Parça - Listagem das Parcerias</h5>
                            </div>

                            <tabela :data="pessoa.parceria">
                                <!-- <template #botoes>
                        <CadPessoaAcordoParceria
                            @atualizarLista="listar()"
                            :idPessoa="idPessoa"
                            :idParceria="0"
                            v-if="$temAcessoView('MAPAPARC-PAR-01')"
                        ></CadPessoaAcordoParceria>
                    </template>  -->

                                <template #conteudo>
                                    <Column headerStyle="width: 5em">
                                        <template #body="slotProps">
                                            <!-- <Detalhes :idParceria="slotProps.data.id"> </Detalhes> -->
                                            <!-- <CadParceria
                                                @atualizarLista="listar()"
                                                :idPessoa="idPessoa"
                                                :idParceria="slotProps.data.id"
                                                v-if="$temAcessoView('MAPAPARC-PAR-02')"
                                            ></CadParceria> -->
                                            <font-awesome-icon
                                                icon="file-pdf"
                                                @click="imprimirPdf(slotProps.data.id)"
                                                size="lg"
                                                class="icon-button p-text-success"
                                                title="Download Pdf"
                                            />
                                        </template>
                                    </Column>
                                    <Column field="numeroAcordo" header="Nº Acordo" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.numeroAcordo }}
                                        </template>
                                    </Column>
                                    <Column field="evento" header="Evento" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.evento }}
                                        </template>
                                    </Column>
                                    <Column field="parceiro.cpfCnpj" header="Cnpj Parceiro" :sortable="true" v-if="!idPessoa > 0">
                                        <template #body="slotProps">
                                            {{ slotProps.data.parceiro.cpfCnpj }}
                                        </template>
                                    </Column>
                                    <Column field="parceiro.razaoSocial" header="Nome Parceiro" :sortable="true" v-if="!idPessoa > 0">
                                        <template #body="slotProps">
                                            {{ slotProps.data.parceiro.razaoSocial }}
                                        </template>
                                    </Column>
                                    <Column field="dataInicioParceria" header="Início Parceria" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.dataInicioParceriaFormatada }}
                                        </template>
                                    </Column>
                                    <Column field="instrumento" header="Instrumento" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.instrumento }}
                                        </template>
                                    </Column>
                                    <Column field="gestorRegional" header="Gestor" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.gestorRegional }}
                                        </template>
                                    </Column>
                                    <!-- <Column field="modalidadeApoio.descricao" header="Modalidade Apoio" :sortable="true">
                            <template #body="slotProps">
                                {{ slotProps.data.modalidadeApoio.descricao }}
                            </template>
                        </Column> -->
                                    <Column field="tags" header="Tags" :sortable="true">
                                        <template #body="slotProps">
                                            {{ slotProps.data.tags }}
                                        </template>
                                    </Column>
                                    <Column field="status" header="Status" :sortable="true">
                                        <template #body="slotProps">
                                            <status :status="slotProps.data.statusText"> </status>
                                        </template>
                                    </Column>
                                </template>
                            </tabela>
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>

        <template #footer>
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>

    <Dialog header="Confirmação" v-model:visible="showDialogExcluir" :style="{ width: '350px' }" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-question-circle mr-3" style="font-size: 2rem" />
            <span>Confirma Exclusão?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" @click="closeConfirmation(false)" class="p-button-text" />
            <Button label="Sim" icon="pi pi-check" @click="closeConfirmation(true)" class="p-button-text" autofocus />
        </template>
    </Dialog>
</template>

<script>
import Services from '../../service.js';
import CadAreaAtuacao from '../cadastro/CadPessoaAreaAtuacao.vue';
import CadMidia from '../cadastro/CadPessoaMidia.vue';
import CadInterlocutores from '../cadastro/CadPessoaInterlocutor.vue';
// import CadPessoaAcordoParceria from '../../parceria/cadastro/CadPessoaAcordoParceria.vue'
// import Parcerias from '../../parceria/lista/ListaParceria.vue'

export default {
    components: {
        CadAreaAtuacao,
        CadMidia,
        CadInterlocutores,
        // CadPessoaAcordoParceria
    },

    props: {
        idPessoa: {
            type: Number,
        },
        tipo: {
            type: Number,
        },
    },

    data() {
        return {
            display: false,

            showDialogExcluir: false,
            objExcluir: {},
            tabelaDelete: '',
            pessoa: {
                setor: {},
                areaAtuacaoPrincipal: {},
                pessoaTipo: [{}],
                pessoaAreaAtuacao: [],
                interlocutoresParceiros: [],
                pessoaMidias: [],
                naturezaJuridica: {},
            },
        };
    },

    methods: {
        showDialog() {
            this.obterPessoa();
            this.display = true;
        },

        closeDialog() {
            this.display = false;
        },

        obterPessoa() {
            this.$store.dispatch('addRequest');
            Services.SelectByIdPessoa(this.idPessoa).then((response) => {
                if (response && response.success) {
                    this.pessoa = response.data;
                } else {
                    this.pessoa = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        imprimirPdf(id) {
            this.$store.dispatch('addRequest');
            Services.ExportarPdf(id).then((response) => {
                if (response) {
                    this.$download(response, 'Parceria.pdf');
                }
                this.$store.dispatch('removeRequest');
            });
        },

        closeConfirmation(confirmacao) {
            if (confirmacao) {
                if (this.tabelaDelete == 'AreaAtuacao') {
                    this.deletarAreaAtuacao();
                } else if (this.tabelaDelete == 'Midia') {
                    this.deletarMidia();
                } else if (this.tabelaDelete == 'Interloc') {
                    this.deletarInterlocutores();
                }
            }
            this.showDialogExcluir = false;
        },

        deletarAreaAtuacao() {
            this.$store.dispatch('addRequest');
            Services.DeletarPessoaAreaAtuacao(this.objExcluir).then((response) => {
                if (response && response.success) {
                    this.obterPessoa();
                }
                this.$store.dispatch('removeRequest');
            });
        },

        deletarMidia() {
            this.$store.dispatch('addRequest');
            Services.DeletarPessoaMidia(this.objExcluir).then((response) => {
                if (response && response.success) {
                    this.obterPessoa();
                }
                this.$store.dispatch('removeRequest');
            });
        },

        deletarInterlocutores() {
            this.$store.dispatch('addRequest');
            Services.DeletarInterlocutoresParceiros(this.objExcluir).then((response) => {
                if (response && response.success) {
                    this.obterPessoa();
                }
                this.$store.dispatch('removeRequest');
            });
        },

        confirmaDeleteArea(obj) {
            this.tabelaDelete = 'AreaAtuacao';
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },

        confirmaDeleteMidia(obj) {
            this.tabelaDelete = 'Midia';
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },

        confirmaDeleteInterlocutores(obj) {
            this.tabelaDelete = 'Interloc';
            this.showDialogExcluir = true;
            this.objExcluir = obj;
        },
    },

    computed: {
        sizeForm() {
            return '200';
        },
    },
};
</script>