<template>
    <Button label="Adicionar" icon="pi pi-plus" class="ml-2" @click="showDialog()" />

    <Dialog v-model:visible="display" no-close-on-backdrop :style="{ width: '40vw' }" :maximizable="false">
        <template #header>
            <h3>Cadastro - Área de Atuação</h3>
        </template>

        <div class="formgrid grid p-fluid">
            <div class="field col">
                <label>Área de Atuação</label>
                <Dropdown v-model="pessoaAreaAtuacao.idAreaAtuacao" :filter="true" :options="combo" optionLabel="descricao" optionValue="id" />
            </div>
        </div>

        <template #footer>
            <Button label="Salvar" @click="salvar()" :disabled="v$.$invalid" />
            <Button label="Fechar" autofocus @click="closeDialog()" />
        </template>
    </Dialog>
</template>



<script>
import Services from '../../service.js';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
    props: {
        pessoa: {
            type: Object,
        },
    },

    emits: ['atualizarLista'],

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            idAreaAtuacao: { required },
        };
    },

    watch: {
        'pessoaAreaAtuacao.idAreaAtuacao'() {
            this.idAreaAtuacao = null;
            if (this.pessoaAreaAtuacao.idAreaAtuacao > 0) {
                this.idAreaAtuacao = this.pessoaAreaAtuacao.idAreaAtuacao;
            }
        },
    },

    data() {
        return {
            display: false,
            combo: [],
            idAreaAtuacao: null,
            pessoaAreaAtuacao: {
                idPessoa: 0,
                idAreaAtuacao: 0,
            },
        };
    },

    methods: {
        showDialog() {
            this.display = true;
            this.carregaCombo();
            this.pessoaAreaAtuacao.idAreaAtuacao = 0;
            this.idAreaAtuacao = null;
        },

        closeDialog() {
            this.display = false;
        },

        carregaCombo() {
            this.$store.dispatch('addRequest');
            Services.SelectAllAreaAtuacao().then((response) => {
                if (response && response.success) {
                    this.combo = response.data;
                } else {
                    this.combo = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        salvar() {
            this.pessoaAreaAtuacao.idPessoa = this.pessoa.id;
            this.$store.dispatch('addRequest');
            Services.InserirPessoaAreaAtuacao(this.pessoaAreaAtuacao).then((response) => {
                if (response && response.success) {
                    this.$emit('atualizarLista');
                }
                this.$store.dispatch('removeRequest');
            });

            this.closeDialog();
        },
    },
};
</script>